*{
 margin: 0;
}
.carousel-item {
  position: relative;
  height: 400px; /* Adjust the height as needed */
}

.carousel-item img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.carousel-item h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  text-align: center;
}

.about{
    width: 95vw;
    height: 60vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}.image{
    width: 30vw;
    height: 50vh;
    background-image: url("https://images.pexels.com/photos/3830880/pexels-photo-3830880.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.heading{
    color: #FF0000;
    overflow-y: hidden;
}

.heroButton{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ff0000;
    color: #ffffff;
    width: 100px;
    height: 40px;
    border-radius: 15px;
}
.heroButton:hover{
    cursor: pointer;
    background-color: blue;
}
.services{
    width: 100vw !important;
    height: max-content !important;
}
.heading, .services-content{
    width: 100vw;
    height: max-content;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.card-title{
    overflow-y: hidden;
}
