* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
}

.carousel-gallery {
    padding: 5rem;
    color: blue;
    width: 100%;
    overflow: hidden;
    height: auto !important;
}


.box {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box img {
    width:100%;
    height: 100%;
    object-fit: contain;
}

.slick-slide {
    margin: 0 1rem !important;
}

.slick-prev, .slick-next {
    display: block !important;
}

.slick-next {
    right: -40px !important;
}

.slick-prev {
    left: -40px !important;
}

.slick-dots {
    bottom: 10px !important;
}

.slick-dots li.slick-active button:before {
    color: red !important;
}

.slick-dots li button:before {
    color: blue !important;
    font-size: 1rem !important;
}
