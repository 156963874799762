.footer {
  width: 100vw;
  height: 50%;
  background-color: #201e43;
  color: white;
}

.footer .footer-contacts {
  width: 100%;
  height: 80%;  
  display: flex;
  justify-content: space-around;
}

.copyright {
  width: 100%;
  height: 20%; 
  display: flex;
  justify-content: center;
}

h4, a {
  line-height: 40px;
}

.socialMedia {
  background-color: #fff;
  width: 30%;
  height: 15%;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.socialMedia .icons {
  width: 65%;
  height: 80%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important; /* This should apply to FontAwesome icons */
}
path{
  color: #ffffff !important;
}

