* {
  margin: 0;
}
.hero {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero .hero-page {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url(https://images.pexels.com/photos/269583/pexels-photo-269583.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-us{
  width: 100vw;
  display: flex;
  justify-content: center;
}
h4{
  color: red;
}