*{
    margin: 0;
    overflow-x: hidden;
    color: blue;
}
h1,
h2,
h3,
h4{
    color: red;
}
p{
    color: blue;
}
a{
    text-decoration: none;
}
h1,
h2,
h3,
h4,
p{
    overflow-y: hidden;
}
.footer .footer-contacts .icons{
    color: white;
}