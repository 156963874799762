/* Navigation.css */
* {
    color: blue;
    margin: 0 !important;
}


.logo {
    width: 85px;
    height: 60px;
}

.page {
    color: blue;
    transition: transform 0.3s, box-shadow 0.3s;
}

.page:hover {
    color: red;
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

