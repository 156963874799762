.services{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.services-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.service{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
h1, h4{
    color: red !important;
    font-weight: bold;
}
p{
    line-height: 30px;
    font-size: 20px;
    font-weight: 400;
}

.serviceImage{
    object-fit: contain;
    width: 300px;
    height: 300px;
}
.service-btn:hover{
    background-color: red;
    cursor: pointer;
    border: none;
}
.service-holder{
    transition: transform 0.3s, box-shadow 0.3s;
}
.service-holder:hover{
    background-color: lightblue;
    cursor: pointer;
    transform: translateY(-10px); /* Moves the element slightly upwards */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}