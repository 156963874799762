.contact{
    display: flex;
    flex-direction: column;
}
.contact .contact-page{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact .contact-page .contact-page-contents{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.contact .contact-page .contact-page-contents .contact-head{
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact .contact-page .contact-page-contents .contact-head h1{
    font-size: 36px;
    font-weight: 400;
    color: red;
    overflow-y: hidden;
}
.contact .contact-page .contact-page-contents .contact-head p{
    font-size: 18px;
    font-weight: 300;
    overflow-y: hidden;
}
.contact .contact-page .contact-page-contents .client-details{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-around;
}