.about-us {
    width: 100vw;
    height: 70vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us .row {
    width: 100%;
    height: 100%;
}

.about-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Adjust alignment as needed */
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
